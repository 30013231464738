/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { navigate } from '@reach/router'

import TextField from '@material-ui/core/TextField'
import Checkbox from "@material-ui/core/Checkbox";
import { FormControlLabel } from "@material-ui/core";
import { PRIMARYCOLOR, 
        ECA_DOQUEIROS, ERENA, FLORESTA_DEPAS, FLORESTA_CASAS_E1, FLORESTA_CASAS_E2, GAMBOA, KANTE, KOH_DEPAS, NAPOLES_DEPAS, PUNTA_CORADISO, 
        PUNTO_LAGUNA_AB, PUNTO_LAGUNA_C, VITRUVIA, XXII, YACHE_LOCALES, YAXCHE_DEPAS, TORRE_ATOYA, MARGARITA_HOUSES, MARGARITA_APARTMENTS_AB, MARGARITA_APARTMENT_C, MARGARITA_APARTMENT_D, MARGARITA_APARTMENT_E } from '../../constants/utils'
import { InverlevyButton } from '../../components/InverlevyButton/InverlevyButton.component'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import endpoints from '../../api-service/endpoints'
import Loader from 'react-loader-spinner'

const StyledDetailsWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items:center;
  justify-content:center;
  flex-flow: column;
`

const StyledPaper = styled.div`
  background-color: white; 
  display: flex;
  flex-flow: column;
  align-items:center;
  justify-content:center;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 2em;
  width:25%;
`
let name = null;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Details = (props) => {
  const [state, setstate] = useState({})
  const [loading, setloading] = useState(false)
  const [error, seterror] = useState({ message: '', display: false })
  const [project, setproject] = useState(null)
  const [project_name, setproject_name] = useState(null)

  useEffect(() => {
    if (props && props.location) {
      const data = { ...props.location.state }
      setproject(data.product)
      setproject_name(data.product)
      const body = {
        "project-id": data.product,
        "unit-id": data.unit.id,
        "trade-policy-id": data.tradepolicy.id,
        "date": data['initial-date'],
        "discount": data.discount,
        "price": data['final-price'],
        "deposit": data['deposit-amount'],
        "additions-payments": [...data['manual-additions'], ...data['additions-payments']],
        "last-payment": data['lastPayment-amount'],
        "condohotel": data.isCondo,
        "currency": data.currency,
        "extra-weeks": data['extra-weeks'],
        "optional-expenses": data.optionalExpenses,
        "has-optional-expenses": data.addOptionalExpenses,
        "condohotel-occupancy": data.occupancy,
        "customer": {}
      }
      setstate({ ...body })
    }
  }, [props])


  switch (project_name) {
      case ECA_DOQUEIROS:
          name = 'EcaDoQueiros'
          break;
      case ERENA:
          name = 'Erena'
          break;
      case FLORESTA_DEPAS:
          name = 'FlorestaDepartamentos'
          break;
      case FLORESTA_CASAS_E1:
          name = 'FlorestaCasasE1'
          break;
      case FLORESTA_CASAS_E2:
          name = 'FlorestaCasasE2'
          break;
      case GAMBOA:
          name = 'Gamboa185'
          break;
      case KOH_DEPAS:
          name = 'KOHApartments'
          break;
      case KANTE:
          name = 'Kante'
          break;
      case MARGARITA_HOUSES:
          name = 'MargaritaHouse'
          break;
      case MARGARITA_APARTMENTS_AB:
          name = 'MargaritaApartmentsAB'
          break;
      case MARGARITA_APARTMENT_C:
          name = 'MargaritaApartmentC'
          break;
      case MARGARITA_APARTMENT_D:
          name = 'MargaritaApartmentD'
          break;
      case MARGARITA_APARTMENT_E:
          name = 'MargaritaApartmentE'
          break;
      case NAPOLES_DEPAS:
          name = 'NapolesApartments'
          break;
      case PUNTA_CORADISO:
          name = 'PuntaCoradiso'
          break;
      case PUNTO_LAGUNA_AB:
          name = 'PuntoLagunaAB'
          break;
      case PUNTO_LAGUNA_C:
          name = 'PuntoLagunaC'
          break;
      case TORRE_ATOYA:
          name = 'TorreAtoya'
          break;
      case VITRUVIA:
          name = 'Vitruvia'
          break;
      case XXII:
          name = 'XXII'
          break;
      case YAXCHE_DEPAS:
          name = 'YaxcheDepartamentos'
          break;
      case YACHE_LOCALES:
          name = 'YaxcheLocalesComerciales'
          break;
  }

  const handleClick = async () => {
    setloading(true)
    try {
      const response = await axios.post(`${endpoints.CALCULATOR}/summary`, state, { responseType: 'blob' })
      const blob = new Blob([response.data], { type: 'application/pdf' })
      
      const link = document.createElement('a')
      link.style = 'display: none'
      document.body.appendChild(link)
      
      const blobURL = URL.createObjectURL(blob)
      link.href = blobURL
      project !== MARGARITA_HOUSES && project !== MARGARITA_APARTMENTS_AB && project !== MARGARITA_APARTMENT_C && project !== MARGARITA_APARTMENT_D && project !== MARGARITA_APARTMENT_E ? (
        link.download = `Cotizacion ` + name +`.pdf`
      ):(
        link.download = `Quote ` + name +`.pdf`
      )
      link.click()
      URL.revokeObjectURL(blobURL)
      navigate('review')
    } catch (error) {
      seterror({ display: true, message: error })
      console.log(error)
    } finally {
      setloading(false)
    }
  }

    return project !== MARGARITA_HOUSES && project !== MARGARITA_APARTMENTS_AB && project !== MARGARITA_APARTMENT_C && project !== MARGARITA_APARTMENT_D && project !== MARGARITA_APARTMENT_E ? (
      <StyledDetailsWrapper>
        <Snackbar open={error.display} autoHideDuration={10000} onClose={() => { seterror({ message: '', display: false }) }}>
          <Alert onClose={() => { seterror({ message: '', display: false }) }} severity="error">
            {`Ocurrió un error (${error.message}) al generar la corrida, por favor intente de nuevo. Si el error persiste contacte soporte soporte@heicommunity.com`}
          </Alert>
        </Snackbar>
        <StyledPaper>
          {loading === true ? <Loader type="ThreeDots" color={PRIMARYCOLOR} />
            : <> <h1 style={{ color: PRIMARYCOLOR }}>Inserta tus datos</h1>
              <TextField onChange={(e) => { setstate({ ...state, customer: { ...state.customer, firstname: e.target.value } }) }} style={{ width: '80%' }} margin="normal" variant="outlined" label="Nombre" placeholder="Nombre" color="secondary" ></TextField>
              <TextField onChange={(e) => { setstate({ ...state, customer: { ...state.customer, lastname: e.target.value } }) }} style={{ width: '80%' }} margin="normal" variant="outlined" label="Apellidos" placeholder="Apellidos" color="secondary" ></TextField>
              <TextField onChange={(e) => { setstate({ ...state, customer: { ...state.customer, email: e.target.value } }) }} style={{ width: '80%' }} margin="normal" variant="outlined" label="Correo electrónico" placeholder="Correo electrónico" color="secondary" ></TextField>
              <TextField onChange={(e) => { setstate({ ...state, customer: { ...state.customer, phone: e.target.value } }) }} style={{ width: '80%' }} margin="normal" variant="outlined" label="Teléfono" placeholder="Teléfono" color="secondary" ></TextField>
              <TextField onChange={(e) => { setstate({ ...state, customer: { ...state.customer, address: e.target.value } }) }} style={{ width: '80%' }} margin="normal" variant="outlined" label="Dirección" placeholder="Dirección" color="secondary"></TextField>
              <TextField onChange={(e) => { setstate({ ...state, customer: { ...state.customer, 'tax-id': e.target.value } }) }} style={{ width: '80%' }} margin="normal" variant="outlined" label="RFC" placeholder="RFC" color="secondary"></TextField>
              <FormControlLabel
                style={{ display: "flex", marginLeft: 5 }}
                control={
                  <Checkbox onChange={(e) => { setstate({ ...state, customer: { ...state.customer, 'crm-track': e.target.checked } }) }} style={{ color: PRIMARYCOLOR, userSelect: "none" }} margin="normal" variant="outlined" />
                }
                label="Cotización final"
              />
              <InverlevyButton onClick={handleClick} >Descargar resumen</InverlevyButton></>}
        </StyledPaper>
      </StyledDetailsWrapper>
    ):(
      <StyledDetailsWrapper>
      <Snackbar open={error.display} autoHideDuration={10000} onClose={() => { seterror({ message: '', display: false }) }}>
        <Alert onClose={() => { seterror({ message: '', display: false }) }} severity="error">
          {`An error (${error.message}) occurred while generating the quote, please try again. If the error persists, contact support at support@heicommunity.com`}
        </Alert>
      </Snackbar>
      <StyledPaper>
        {loading === true ? <Loader type="ThreeDots" color={PRIMARYCOLOR} />
          : <> <h1 style={{ color: PRIMARYCOLOR }}>Contact information</h1>
            <TextField onChange={(e) => { setstate({ ...state, customer: { ...state.customer, firstname: e.target.value } }) }} style={{ width: '80%' }} margin="normal" variant="outlined" label="Name" placeholder="Name" color="secondary" ></TextField>
            <TextField onChange={(e) => { setstate({ ...state, customer: { ...state.customer, lastname: e.target.value } }) }} style={{ width: '80%' }} margin="normal" variant="outlined" label="Last name" placeholder="Last name" color="secondary" ></TextField>
            <TextField onChange={(e) => { setstate({ ...state, customer: { ...state.customer, email: e.target.value } }) }} style={{ width: '80%' }} margin="normal" variant="outlined" label="Email" placeholder="Email" color="secondary" ></TextField>
            <TextField onChange={(e) => { setstate({ ...state, customer: { ...state.customer, phone: e.target.value } }) }} style={{ width: '80%' }} margin="normal" variant="outlined" label="Phone" placeholder="Phone" color="secondary" ></TextField>
            <TextField onChange={(e) => { setstate({ ...state, customer: { ...state.customer, address: e.target.value } }) }} style={{ width: '80%' }} margin="normal" variant="outlined" label="Address" placeholder="Address" color="secondary"></TextField>
            <TextField onChange={(e) => { setstate({ ...state, customer: { ...state.customer, 'tax-id': e.target.value } }) }} style={{ width: '80%' }} margin="normal" variant="outlined" label="RFC" placeholder="RFC" color="secondary"></TextField>
            <FormControlLabel
              style={{ display: "flex", marginLeft: 5 }}
              control={
                <Checkbox onChange={(e) => { setstate({ ...state, customer: { ...state.customer, 'crm-track': e.target.checked } }) }} style={{ color: PRIMARYCOLOR, userSelect: "none" }} margin="normal" variant="outlined" />
              }
              label="Final quote"
            />
            <InverlevyButton onClick={handleClick} >Download summary</InverlevyButton></>}
      </StyledPaper>
    </StyledDetailsWrapper>
    )
}

export default Details
