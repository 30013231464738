export const PRIMARYCOLOR = '#005CB2'
export const PRIMARYBLUE = '#015cb2'
export const PRIMARYRED = '#a80e0e'
export const LH_PRIMARY_COLOR = '#99CCCC'
export const HEI_PRIMARY_COLOR = '#a80e0e'

export const MONEYFORMATTER = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'

  // These options are needed to round to whole numbers if that's what you want.
  // minimumFractionDigits: 0,
  // maximumFractionDigits: 0,
})

export const SUPERADMIN_ROLE = 'super_admin'
export const ADMIN_ROLE = 'admin'
export const ADMIN_REALTOR_ROLE = 'admin_realtor'
export const ADMIN_TRAVEL_ROLE = 'admin_travel'
export const AGENT_ROLE = 'agent_role'
export const AGENT_ALCAZA_ROLE = 'agent_alcaza'
export const AGENT_ATOYA_ROLE = 'agent_atoya'
export const AGENT_CORADISO_ROLE = 'agent_coradiso'
export const AGENT_FLORESTA_ROLE = 'agent_floresta'
export const AGENT_FLORESTA_DEPA_ROLE = 'agent_floresta_depa'
export const AGENT_GAMBOA_ROLE = 'agent_gamboa'
export const AGENT_KANTE_ROLE = 'agent_kante'
export const AGENT_KOH_ROLE = 'agent_koh'
export const AGENT_LAGUNA_ABC_ROLE = 'agent_laguna_abc'
export const AGENT_MARGARITA_ROLE = 'agent_margarita'
export const AGENT_NAPOLES_ROLE = 'agent_napoles'
export const AGENT_ECA_ROLE = 'agent_eca'
export const AGENT_XXII_ROLE = 'agent_xxll'
export const AGENT_YAXCHE_ROLE = 'agent_yaxche'



export const ECA_DOQUEIROS = '7ce1e2f0-fff7-41c6-aa4a-e12ee4c0d1ca'
export const ERENA = '2bf51eb7-9d24-4261-8f59-ba22705a88c1'
export const FLORESTA_DEPAS = '2a657022-69c7-4126-a833-3627d6ce7e5c'
export const FLORESTA_CASAS_E1 = 'f84de1e4-1c26-457a-985f-b5b7b8b09b3a'
export const FLORESTA_CASAS_E2 = '6776169d-8b31-45b2-8abb-e3c218c086d1'
export const GAMBOA = '780a4c2e-009d-455c-baac-5302ac6981db'
export const KANTE = '005df06f-7072-4e38-9dff-aa8a649da4db'
export const KOH_DEPAS = '88fe853a-5ab9-44f1-bf5a-e1a630aad784'
export const MARGARITA_HOUSES = '26b69d98-e6db-493d-bf51-0b66a2d9fa1f'
export const MARGARITA_APARTMENTS_AB = 'e626442c-b13f-45ca-99e9-73cc4e6fa135'
export const MARGARITA_APARTMENT_C = 'e626442c-b13f-45ca-99e9-73cc4e6fa136'
export const MARGARITA_APARTMENT_D = 'e626442c-b13f-45ca-99e9-73cc4e6fa137'
export const MARGARITA_APARTMENT_E = 'e626442c-b13f-45ca-99e9-73cc4e6fa138'
export const NAPOLES_DEPAS = 'eb04a05e-0408-4d9b-9b27-71a6c8ee1b4d'
export const PUNTA_CORADISO = 'fa201862-f224-4abc-b546-4189058580b7'
export const PUNTO_LAGUNA_AB = 'c4a01bee-d182-4caa-84d4-7bb5f016bd00'
export const PUNTO_LAGUNA_C = '49c7df64-9be1-40ab-afcc-b96ad92d870a'
export const TORRE_ATOYA = '9a36c2b1-243c-4e6e-881b-2757a4b06aec'
export const VITRUVIA = '5e4cb7bb-1de2-44a8-a737-c7905fbfaebe'
export const XXII = 'c5291cbb-1397-4c2d-b7e5-d0cd39c58e09'
export const YAXCHE_DEPAS = '994c787d-15f4-4a7d-ad11-c5fd9a0a6b3b'
export const YACHE_LOCALES = 'a2842480-292d-415f-af51-556922bac68d'


