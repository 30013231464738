import React, { useContext } from 'react'
import styled from 'styled-components'
import { InverlevyButton } from '../../../components/InverlevyButton/InverlevyButton.component'
import { InverlevyButtonDisable } from '../../../components/InverlevyButton/InverlevyButtonDisable.component'
import { navigate } from '@reach/router'
import InverlevyStyledWrapper from '../../../components/InverlevyWrapper/InverlevyWrapper.component'
import InverlevyHeader from '../../../components/InverlevyHeader/InverlevyHeader.component'
import {
  AGENT_ATOYA_ROLE, AGENT_CORADISO_ROLE, AGENT_FLORESTA_ROLE, SUPERADMIN_ROLE, AGENT_ROLE,
  AGENT_GAMBOA_ROLE, AGENT_KOH_ROLE, AGENT_KANTE_ROLE, AGENT_LAGUNA_ABC_ROLE, AGENT_MARGARITA_ROLE, AGENT_ECA_ROLE, AGENT_XXII_ROLE, AGENT_YAXCHE_ROLE,
  PRIMARYCOLOR,
  AGENT_ALCAZA_ROLE,
  AGENT_NAPOLES_ROLE
} from '../../../constants/utils'
import { AuthContext } from '../../../providers/session/Auth.provider'
import axios from 'axios'
import fileDownload from 'js-file-download'
import Fade from 'react-reveal/Fade';

const StyledInfoWrapper = styled.div`
  width: 100%;
  height: ${(p) => (p.cut ? 'calc(100vh - 56px)' : '100vh')};
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  background-color: ${(p) =>
    p.backgroundColor ? p.backgroundColor : ' white'};
`

const StyledP = styled.p`
  color: ${PRIMARYCOLOR};
  font-size: 1.5em;
  font-weight: lighter;
`
const handleDownload = (filename) => {
  axios.get('https://heicommunityquotes.blob.core.windows.net/projects/' + filename, {
    responseType: 'blob',
    transformRequest: (data, headers) => {
      delete headers.common.Authorization
      return data
    }
  }).then((res) => {
    fileDownload(res.data, filename)
  })
}

const Welcome = () => {
  const { state } = useContext(AuthContext)
  return (
    <InverlevyStyledWrapper>
      {state.loggedIn && (state.roles.includes(SUPERADMIN_ROLE) || state.roles.includes(AGENT_FLORESTA_ROLE) || state.roles.includes(AGENT_ROLE)) && <StyledInfoWrapper>
        <Fade left>
          <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img
              style={{ borderRadius: '50px', width: '90%' }}
              src={'https://heicommunitytmp.blob.core.windows.net/realtor/projects/main/6776169d-8b31-45b2-8abb-e3c218c086d1.jpg'}
              alt="Floresta Casas E1"
            />
          </div>
        </Fade>
        <Fade right cascade>
          <div style={{ width: '60%', margin: ' 0 auto' }}>
            <InverlevyHeader fontSize={'4em'} color={'#003f44'}>
              Floresta Casas E1
            </InverlevyHeader>
            <StyledP>El mejor escape de la ciudad</StyledP>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton type="button" backgroundColor={PRIMARYCOLOR} borderColor={PRIMARYCOLOR}
                onClick={() =>
                  navigate('/quote?product=f84de1e4-1c26-457a-985f-b5b7b8b09b3a')
                }
              >
                Cotizar
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Floresta_casas/Presentacion_Floresta_Casas.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Presentación
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2' borderColor={PRIMARYCOLOR}
              //onClick={() =>
              //navigate('')
              //}
              >
                Video recorrido
              </InverlevyButtonDisable>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Floresta_casas/Teaser_Floresta_Casas.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Teaser
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('')
              // }
              >
                Lista precios
              </InverlevyButtonDisable>
              &nbsp;
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2'
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Floresta_casas/Planos_Floresta_Casas.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Planos
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('')
              // }
              >
                Obra
              </InverlevyButtonDisable>
            </div>
          </div>
        </Fade>
      </StyledInfoWrapper>}

      {state.loggedIn && (state.roles.includes(SUPERADMIN_ROLE) || state.roles.includes(AGENT_FLORESTA_ROLE) || state.roles.includes(AGENT_ROLE)) && <StyledInfoWrapper backgroundColor={'#F1F3F5'}>
        <Fade left cascade>
          <div style={{ width: '60%', margin: ' 0 auto' }}>
            <InverlevyHeader fontSize={'4em'} color={'#003f44'}>
              Floresta Casas E2
            </InverlevyHeader>
            <StyledP>El mejor escape de la ciudad</StyledP>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton type="button" backgroundColor={PRIMARYCOLOR} borderColor={PRIMARYCOLOR}
                onClick={() =>
                  navigate('/quote?product=6776169d-8b31-45b2-8abb-e3c218c086d1')
                }
              >
                Cotizar
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Floresta_casas/Presentacion_Floresta_Casas.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Presentación
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2' borderColor={PRIMARYCOLOR}
              //onClick={() => 
              //window.open('', '_blank', 'noopener noreferrer')
              //}
              >
                Video recorrido
              </InverlevyButtonDisable>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Floresta_casas/Teaser_Floresta_Casas.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Teaser
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Lista precios
              </InverlevyButtonDisable>
              &nbsp;
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Floresta_casas/Planos_Floresta_Casas.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Planos
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Obra
              </InverlevyButtonDisable>
            </div>
          </div>
        </Fade>
        <Fade right>
          <div>
            <img
              style={{ borderRadius: '50px', width: '90%' }}
              src={'https://heicommunitytmp.blob.core.windows.net/realtor/projects/main/6776169d-8b31-45b2-8abb-e3c218c086d1.jpg'}
              alt="Floresta Casas E2"
            />
          </div>
        </Fade>
      </StyledInfoWrapper>}

      {state.loggedIn && (state.roles.includes(SUPERADMIN_ROLE) || state.roles.includes(AGENT_GAMBOA_ROLE) || state.roles.includes(AGENT_ROLE)) && <StyledInfoWrapper>
        <Fade left>
          <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img
              style={{ borderRadius: '50px', width: '90%' }}
              src={'https://heicommunitytmp.blob.core.windows.net/realtor/projects/main/780a4c2e-009d-455c-baac-5302ac6981db.jpg'}
              alt="Gamboa"
            />
          </div>
        </Fade>
        <Fade right cascade>
          <div style={{ width: '60%', margin: ' 0 auto' }}>
            <InverlevyHeader fontSize={'4em'} color={'#003f44'}>
              Gamboa Oficinas
            </InverlevyHeader>
            <StyledP></StyledP>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton type="button" backgroundColor={PRIMARYCOLOR} borderColor={PRIMARYCOLOR}
                onClick={() =>
                  navigate('/quote?product=780a4c2e-009d-455c-baac-5302ac6981db')
                }
              >
                Cotizar
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Gamboa/Presentacion_Gamboa.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Presentación
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2' borderColor={PRIMARYCOLOR}
              //onClick={() =>
              //navigate('')
              //}
              >
                Video recorrido
              </InverlevyButtonDisable>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
              onClick={() =>
                window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Gamboa/Teaser_Gamboa.pdf', '_blank', 'noopener noreferrer')
              }
              >
                Teaser
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('')
              // }
              >
                Lista precios
              </InverlevyButtonDisable>
              &nbsp;
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2'
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Gamboa/Planos_Gamboa.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Planos
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('')
              // }
              >
                Obra
              </InverlevyButtonDisable>
            </div>
          </div>
        </Fade>
      </StyledInfoWrapper>}

      {state.loggedIn && (state.roles.includes(SUPERADMIN_ROLE) || state.roles.includes(AGENT_KANTE_ROLE) || state.roles.includes(AGENT_ROLE)) && <StyledInfoWrapper backgroundColor={'#F1F3F5'}>
        <Fade left cascade>
          <div style={{ width: '60%', margin: ' 0 auto' }}>
            <InverlevyHeader fontSize={'4em'} color={'#375623'}>
              Kante Apartments
            </InverlevyHeader>
            <StyledP>Departamentos sustentables</StyledP>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton type="button" backgroundColor={PRIMARYCOLOR} borderColor={PRIMARYCOLOR}
                onClick={() =>
                  navigate('/quote?product=005df06f-7072-4e38-9dff-aa8a649da4db')
                }
              >
                Cotizar
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Kante/Presentacion_Kante.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Presentación
              </InverlevyButton>
              &nbsp;
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://www.youtube.com/watch?v=uCtp9Fi2cls&list=PLO4XW3c8uHP02B4CNH-YWCPSYBZZ77aJC&index=5', '_blank', 'noopener noreferrer')
                }
              >
                Video recorrido
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Kante/Teaser_Kante.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Teaser
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Lista precios
              </InverlevyButtonDisable>
              &nbsp;
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Kante/Planos_Kante.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Planos
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Obra
              </InverlevyButtonDisable>
            </div>
          </div>
        </Fade>
        <Fade right>
          <div>
            <img
              style={{ borderRadius: '50px', width: '90%' }}
              src={'https://heicommunitytmp.blob.core.windows.net/realtor/projects/main/005df06f-7072-4e38-9dff-aa8a649da4db.jpg'}
              alt="Kante"
            />
          </div>
        </Fade>
      </StyledInfoWrapper>}

      {state.loggedIn && (state.roles.includes(SUPERADMIN_ROLE) || state.roles.includes(AGENT_KOH_ROLE) || state.roles.includes(AGENT_ROLE)) && <StyledInfoWrapper>
        <Fade left>
          <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img
              style={{ borderRadius: '50px', width: '90%' }}
              src={'https://heicommunitytmp.blob.core.windows.net/realtor/projects/main/88fe853a-5ab9-44f1-bf5a-e1a630aad784.jpg'}
              alt="Koh"
            />
          </div>
        </Fade>
        <Fade right cascade>
          <div style={{ width: '60%', margin: ' 0 auto' }}>
            <InverlevyHeader fontSize={'4em'} color={'#806f55'}>
              Koh Apartments
            </InverlevyHeader>
            <StyledP>Un espacio en la selva</StyledP>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton type="button" backgroundColor={PRIMARYCOLOR} borderColor={PRIMARYCOLOR}
                onClick={() =>
                  navigate('/quote?product=88fe853a-5ab9-44f1-bf5a-e1a630aad784')
                }
              >
                Cotizar
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Koh/Presentacion_Koh.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Presentación
              </InverlevyButton>
              &nbsp;
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://www.youtube.com/watch?v=ECiH4C9H53c&list=PLO4XW3c8uHP02B4CNH-YWCPSYBZZ77aJC&index=8', '_blank', 'noopener noreferrer')
                }
              >
                Video recorrido
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Koh/Teaser_Koh.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Teaser
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Lista precios
              </InverlevyButtonDisable>
              &nbsp;
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2'
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Koh/Planos_Koh.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Planos
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Obra
              </InverlevyButtonDisable>
            </div>
          </div>
        </Fade>
      </StyledInfoWrapper>}

      {state.loggedIn && (state.roles.includes(SUPERADMIN_ROLE) || state.roles.includes(AGENT_MARGARITA_ROLE) || state.roles.includes(AGENT_ROLE)) && <StyledInfoWrapper backgroundColor={'#F1F3F5'}>
        <Fade left cascade>
          <div style={{ width: '60%', margin: ' 0 auto' }}>
            <InverlevyHeader fontSize={'4em'} color={'#01a7bb'}>
            Latitude Margaritaville International Lake Chapala
            </InverlevyHeader>
            <StyledP>Houses</StyledP>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton type="button" backgroundColor={PRIMARYCOLOR} borderColor={PRIMARYCOLOR}
                onClick={() =>
                  navigate('/quote?product=26b69d98-e6db-493d-bf51-0b66a2d9fa1f')
                }
              >
                Quote
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Margarita_houses/Presentacion_Margarita_Houses.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Presentation
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2' borderColor={PRIMARYCOLOR}
                //onClick={() =>
                //  window.open('https://www.youtube.com/watch?v=3Sru9TZjj60&list=PLO4XW3c8uHP02B4CNH-YWCPSYBZZ77aJC&index=7', '_blank', 'noopener noreferrer')
                //}
              >
                Virtual tour
              </InverlevyButtonDisable>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Margarita_houses/Teaser_Margarita_Houses.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Teaser
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Price list
              </InverlevyButtonDisable>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2' borderColor={PRIMARYCOLOR}
                // onClick={() =>
                //   window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Margarita_houses/Planos_Napoles.pdf', '_blank', 'noopener noreferrer')
                // }
              >
                Plans
              </InverlevyButtonDisable>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Progress
              </InverlevyButtonDisable>
            </div>
          </div>
        </Fade>
        <Fade right>
          <div>
            <img
              style={{ borderRadius: '50px', width: '90%' }}
              src={'https://heicommunitytmp.blob.core.windows.net/realtor/projects/main/26b69d98-e6db-493d-bf51-0b66a2d9fa1f.jpg'}
              alt="Latitude Margaritaville International Lake Chapala (Houses)"
            />
          </div>
        </Fade>
      </StyledInfoWrapper>}

      {state.loggedIn && (state.roles.includes(SUPERADMIN_ROLE) || state.roles.includes(AGENT_MARGARITA_ROLE) || state.roles.includes(AGENT_ROLE)) && <StyledInfoWrapper>
        <Fade left>
          <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img
              style={{ borderRadius: '50px', width: '90%' }}
              src={'https://heicommunitytmp.blob.core.windows.net/realtor/projects/main/e626442c-b13f-45ca-99e9-73cc4e6fa135.jpg'}
              alt="Latitude Margaritaville International Lake Chapala (Apartments A & B)"
            />
          </div>
        </Fade>
        <Fade right cascade>
          <div style={{ width: '60%', margin: ' 0 auto' }}>
            <InverlevyHeader fontSize={'4em'} color={'#01a7bb'}>
            Latitude Margaritaville International Lake Chapala
            </InverlevyHeader>
            <StyledP>Apartments A & B</StyledP>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton type="button" backgroundColor={PRIMARYCOLOR} borderColor={PRIMARYCOLOR}
                onClick={() =>
                  navigate('/quote?product=e626442c-b13f-45ca-99e9-73cc4e6fa135')
                }
              >
                Quote
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                 onClick={() =>
                   window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Margarita_apartments_AB/Presentacion_Margarita_Apartments.pdf', '_blank', 'noopener noreferrer')
                 }
              >
                Presentation
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2' borderColor={PRIMARYCOLOR}
                 //onClick={() =>
                  // window.open('https://www.youtube.com/watch?v=uO4c_ejQfr8&list=PLO4XW3c8uHP02B4CNH-YWCPSYBZZ77aJC&index=6', '_blank', 'noopener noreferrer')
                 //}
              >
                Virtual tour
              </InverlevyButtonDisable>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                 onClick={() =>
                   window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Margarita_apartments_AB/Teaser_Margarita_Apartments.pdf', '_blank', 'noopener noreferrer')
                 }
              >
                Teaser
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Price list
              </InverlevyButtonDisable>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2' borderColor={PRIMARYCOLOR}
                 //onClick={() =>
                 //  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Margarita_apartments_AB/Planos_Margarita_Apartments.pdf', '_blank', 'noopener noreferrer')
                 //}
              >
                Plans
              </InverlevyButtonDisable>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Progress
              </InverlevyButtonDisable>
            </div>
          </div>
        </Fade>
      </StyledInfoWrapper>}

      {state.loggedIn && (state.roles.includes(SUPERADMIN_ROLE) || state.roles.includes(AGENT_MARGARITA_ROLE) || state.roles.includes(AGENT_ROLE)) && <StyledInfoWrapper backgroundColor={'#F1F3F5'}>
        <Fade left cascade>
          <div style={{ width: '60%', margin: ' 0 auto' }}>
            <InverlevyHeader fontSize={'4em'} color={'#01a7bb'}>
              Latitude Margaritaville International Lake Chapala
            </InverlevyHeader>
            <StyledP>Apartment C</StyledP>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton type="button" backgroundColor={PRIMARYCOLOR} borderColor={PRIMARYCOLOR}
                onClick={() =>
                  navigate('/quote?product=e626442c-b13f-45ca-99e9-73cc4e6fa136')
                }
              >
                Quote
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Margarita_apartments_C/Presentacion_Margarita_Apartments.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Presentation
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                //onClick={() =>
                  //window.open('https://www.youtube.com/watch?v=3Sru9TZjj60&list=PLO4XW3c8uHP02B4CNH-YWCPSYBZZ77aJC&index=7', '_blank', 'noopener noreferrer')
                //}
              >
                Virtual tour
              </InverlevyButtonDisable>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Margarita_apartments_C/Teaser_Margarita_Apartments.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Teaser
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Price list
              </InverlevyButtonDisable>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2' borderColor={PRIMARYCOLOR}
                //onClick={() =>
                  //window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Margarita_apartments_C/Planos_Margarita_Apartments.pdf', '_blank', 'noopener noreferrer')
                //}
              >
                Plans
              </InverlevyButtonDisable>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Progress
              </InverlevyButtonDisable>
            </div>
          </div>
        </Fade>
        <Fade right>
          <div>
            <img
              style={{ borderRadius: '50px', width: '90%' }}
              src={'https://heicommunitytmp.blob.core.windows.net/realtor/projects/main/e626442c-b13f-45ca-99e9-73cc4e6fa136.jpg'}
              alt="Latitude Margaritaville International Lake Chapala (Apartment C)"
            />
          </div>
        </Fade>
      </StyledInfoWrapper>}

      {state.loggedIn && (state.roles.includes(SUPERADMIN_ROLE) || state.roles.includes(AGENT_MARGARITA_ROLE) || state.roles.includes(AGENT_ROLE)) && <StyledInfoWrapper>
        <Fade left>
          <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img
              style={{ borderRadius: '50px', width: '90%' }}
              src={'https://heicommunitytmp.blob.core.windows.net/realtor/projects/main/e626442c-b13f-45ca-99e9-73cc4e6fa137.jpg'}
              alt="Latitude Margaritaville International Lake Chapala (Apartment D)"
            />
          </div>
        </Fade>
        <Fade right cascade>
          <div style={{ width: '60%', margin: ' 0 auto' }}>
            <InverlevyHeader fontSize={'4em'} color={'#01a7bb'}>
            Latitude Margaritaville International Lake Chapala
            </InverlevyHeader>
            <StyledP>Apartment D</StyledP>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton type="button" backgroundColor={PRIMARYCOLOR} borderColor={PRIMARYCOLOR}
                onClick={() =>
                  navigate('/quote?product=e626442c-b13f-45ca-99e9-73cc4e6fa137')
                }
              >
                Quote
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                 onClick={() =>
                   window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Margarita_apartments_D/Presentacion_Margarita_Apartments.pdf', '_blank', 'noopener noreferrer')
                 }
              >
                Presentation
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2' borderColor={PRIMARYCOLOR}
                 //onClick={() =>
                  // window.open('https://www.youtube.com/watch?v=uO4c_ejQfr8&list=PLO4XW3c8uHP02B4CNH-YWCPSYBZZ77aJC&index=6', '_blank', 'noopener noreferrer')
                 //}
              >
                Virtual tour
              </InverlevyButtonDisable>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                 onClick={() =>
                   window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Margarita_apartments_D/Teaser_Margarita_Apartments.pdf', '_blank', 'noopener noreferrer')
                 }
              >
                Teaser
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Price list
              </InverlevyButtonDisable>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2' borderColor={PRIMARYCOLOR}
                 //onClick={() =>
                 //  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Margarita_apartments_D/Planos_Margarita_apartments.pdf', '_blank', 'noopener noreferrer')
                 //}
              >
                Plans
              </InverlevyButtonDisable>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Progress
              </InverlevyButtonDisable>
            </div>
          </div>
        </Fade>
      </StyledInfoWrapper>}

      {state.loggedIn && (state.roles.includes(SUPERADMIN_ROLE) || state.roles.includes(AGENT_MARGARITA_ROLE) || state.roles.includes(AGENT_ROLE)) && <StyledInfoWrapper backgroundColor={'#F1F3F5'}>
        <Fade left cascade>
          <div style={{ width: '60%', margin: ' 0 auto' }}>
            <InverlevyHeader fontSize={'4em'} color={'#01a7bb'}>
              Latitude Margaritaville International Lake Chapala
            </InverlevyHeader>
            <StyledP>Apartment E</StyledP>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton type="button" backgroundColor={PRIMARYCOLOR} borderColor={PRIMARYCOLOR}
                onClick={() =>
                  navigate('/quote?product=e626442c-b13f-45ca-99e9-73cc4e6fa138')
                }
              >
                Quote
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Margarita_apartments_E/Presentacion_Margarita_Apartments.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Presentation
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                //onClick={() =>
                  //window.open('https://www.youtube.com/watch?v=3Sru9TZjj60&list=PLO4XW3c8uHP02B4CNH-YWCPSYBZZ77aJC&index=7', '_blank', 'noopener noreferrer')
                //}
              >
                Virtual tour
              </InverlevyButtonDisable>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Margarita_apartments_E/Teaser_Margarita_Apartments.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Teaser
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Price list
              </InverlevyButtonDisable>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2' borderColor={PRIMARYCOLOR}
                //onClick={() =>
                  //window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Margarita_apartments_E/Planos_Margarita_apartments_E.pdf', '_blank', 'noopener noreferrer')
                //}
              >
                Plans
              </InverlevyButtonDisable>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Progress
              </InverlevyButtonDisable>
            </div>
          </div>
        </Fade>
        <Fade right>
          <div>
            <img
              style={{ borderRadius: '50px', width: '90%' }}
              src={'https://heicommunitytmp.blob.core.windows.net/realtor/projects/main/e626442c-b13f-45ca-99e9-73cc4e6fa138.jpg'}
              alt="Latitude Margaritaville International Lake Chapala (Apartment E)"
            />
          </div>
        </Fade>
      </StyledInfoWrapper>}

      {state.loggedIn && (state.roles.includes(SUPERADMIN_ROLE) || state.roles.includes(AGENT_NAPOLES_ROLE) || state.roles.includes(AGENT_ROLE)) && <StyledInfoWrapper>
        <Fade left>
          <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img
              style={{ borderRadius: '50px', width: '90%' }}
              src={'https://heicommunitytmp.blob.core.windows.net/realtor/projects/main/eb04a05e-0408-4d9b-9b27-71a6c8ee1b4d.jpg'}
              alt="Napoles"
            />
          </div>
        </Fade>
        <Fade right cascade>
          <div style={{ width: '60%', margin: ' 0 auto' }}>
            <InverlevyHeader fontSize={'4em'} color={'#ff9619'}>
              Nápoles Apartments
            </InverlevyHeader>
            <StyledP>High tech apartments</StyledP>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton type="button" backgroundColor={PRIMARYCOLOR} borderColor={PRIMARYCOLOR}
                onClick={() =>
                  navigate('/quote?product=eb04a05e-0408-4d9b-9b27-71a6c8ee1b4d')
                }
              >
                Cotizar
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                 onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Napoles/Presentacion_Napoles.pdf', '_blank', 'noopener noreferrer')
                 }
              >
                Presentación
              </InverlevyButton>
              &nbsp;
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://www.youtube.com/watch?v=3Sru9TZjj60&list=PLO4XW3c8uHP02B4CNH-YWCPSYBZZ77aJC&index=7', '_blank', 'noopener noreferrer')
                }
              >
                Video recorrido
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                 onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Napoles/Teaser_Napoles.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Teaser
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Lista precios
              </InverlevyButtonDisable>
              &nbsp;
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                 onClick={() =>
                   window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Napoles/Planos_Napoles.pdf', '_blank', 'noopener noreferrer')
                 }
              >
               Planos
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Obra
              </InverlevyButtonDisable>
            </div>
          </div>
        </Fade>
      </StyledInfoWrapper>}

      {state.loggedIn && (state.roles.includes(SUPERADMIN_ROLE) || state.roles.includes(AGENT_CORADISO_ROLE) || state.roles.includes(AGENT_ROLE)) && <StyledInfoWrapper backgroundColor={'#F1F3F5'}>
        <Fade left cascade>
          <div style={{ width: '60%', margin: ' 0 auto' }}>
            <InverlevyHeader fontSize={'4em'} color={'#843c26'}>
              Punta Coradiso
            </InverlevyHeader>
            <StyledP>Un paraíso tropical</StyledP>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton type="button" backgroundColor={PRIMARYCOLOR} borderColor={PRIMARYCOLOR}
                onClick={() =>
                  navigate('/quote?product=fa201862-f224-4abc-b546-4189058580b7')
                }
              >
                Cotizar
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                 onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Coradiso/Presentacion_Coradiso.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Presentación
              </InverlevyButton>
              &nbsp;
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
               onClick={() =>
                window.open('https://www.youtube.com/watch?v=uO4c_ejQfr8&list=PLO4XW3c8uHP02B4CNH-YWCPSYBZZ77aJC&index=6', '_blank', 'noopener noreferrer')
              }
              >
                Video recorrido
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Coradiso/Teaser_Coradiso.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Teaser
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Lista precios
              </InverlevyButtonDisable>
              &nbsp;
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Coradiso/Planos_Coradiso.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Planos
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Obra
              </InverlevyButtonDisable>
            </div>
          </div>
        </Fade>
        <Fade right>
          <div>
            <img
              style={{ borderRadius: '50px', width: '90%' }}
              src={'https://heicommunitytmp.blob.core.windows.net/realtor/projects/main/fa201862-f224-4abc-b546-4189058580b7.jpg'}
              alt="Punta Coradiso"
            />
          </div>
        </Fade>
      </StyledInfoWrapper>}

      {state.loggedIn && (state.roles.includes(SUPERADMIN_ROLE) || state.roles.includes(AGENT_LAGUNA_ABC_ROLE) || state.roles.includes(AGENT_ROLE)) && <StyledInfoWrapper>
        <Fade left>
          <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img
              style={{ borderRadius: '50px', width: '90%' }}
              src={'https://heicommunitytmp.blob.core.windows.net/realtor/projects/main/c4a01bee-d182-4caa-84d4-7bb5f016bd00.jpg'}
              alt="Entorno Laguna A+B"
            />
          </div>
        </Fade>
        <Fade right cascade>
          <div style={{ width: '60%', margin: ' 0 auto' }}>
            <InverlevyHeader fontSize={'4em'} color={'#442464'}>
              Entorno Laguna A+B
            </InverlevyHeader>
            <StyledP>El mejor escape de la ciudad</StyledP>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton type="button" backgroundColor={PRIMARYCOLOR} borderColor={PRIMARYCOLOR}
                onClick={() =>
                  navigate('/quote?product=c4a01bee-d182-4caa-84d4-7bb5f016bd00')
                }
              >
                Cotizar
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Laguna_AB/Presentacion_Laguna_A+B.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Presentación
              </InverlevyButton>
              &nbsp;
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://www.youtube.com/watch?v=z4_U-9QJZp0&list=PLO4XW3c8uHP02B4CNH-YWCPSYBZZ77aJC&index=1', '_blank', 'noopener noreferrer')
                }
              >
                Video recorrido
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Laguna_AB/Teaser_Laguna_A+B.pdf', '_blank', 'noopener noreferrer')
                } 
              >
                Teaser
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Lista precios
              </InverlevyButtonDisable>
              &nbsp;
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                 onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Laguna_AB/Planos_Laguna_A+B.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Planos
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Obra
              </InverlevyButtonDisable>
            </div>
          </div>
        </Fade>
      </StyledInfoWrapper>}

      {state.loggedIn && (state.roles.includes(SUPERADMIN_ROLE) || state.roles.includes(AGENT_LAGUNA_ABC_ROLE) || state.roles.includes(AGENT_ROLE)) && <StyledInfoWrapper backgroundColor={'#F1F3F5'}>
        <Fade left cascade>
          <div style={{ width: '60%', margin: ' 0 auto' }}>
            <InverlevyHeader fontSize={'4em'} color={'#D6A285'}>
              Entorno Laguna C
            </InverlevyHeader>
            <StyledP>Conecta con lo escencial</StyledP>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton type="button" backgroundColor={PRIMARYCOLOR} borderColor={PRIMARYCOLOR}
                onClick={() =>
                  navigate('/quote?product=49c7df64-9be1-40ab-afcc-b96ad92d870a')
                }
              >
                Cotizar
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                 onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Laguna_C/Presentacion_Laguna_C.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Presentación
              </InverlevyButton>
              &nbsp;
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://www.youtube.com/watch?v=z4_U-9QJZp0&list=PLO4XW3c8uHP02B4CNH-YWCPSYBZZ77aJC&index=1', '_blank', 'noopener noreferrer')
                }
              >
                Video recorrido
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Laguna_C/Teaser_Laguna_C.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Teaser
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('')
              // }
              >
                Lista precios
              </InverlevyButtonDisable>
              &nbsp;
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Laguna_C/Planos_Laguna_C.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Planos
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Obra
              </InverlevyButtonDisable>
            </div>
          </div>
        </Fade>
        <Fade right>
          <div>
            <img
              style={{ borderRadius: '50px', width: '90%' }}
              src={'https://heicommunitytmp.blob.core.windows.net/realtor/projects/main/49c7df64-9be1-40ab-afcc-b96ad92d870a.jpg'}
              alt="Entorno Laguna C"
            />
          </div>
        </Fade>
      </StyledInfoWrapper>}

      {state.loggedIn && (state.roles.includes(SUPERADMIN_ROLE) || state.roles.includes(AGENT_ATOYA_ROLE) || state.roles.includes(AGENT_ROLE)) && <StyledInfoWrapper>
        <Fade left>
          <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img
              style={{ borderRadius: '50px', width: '90%' }}
              src={'https://heicommunitytmp.blob.core.windows.net/realtor/projects/main/9a36c2b1-243c-4e6e-881b-2757a4b06aec.jpg'}
              alt="Atoya"
            />
          </div>
        </Fade>
        <Fade right cascade>
          <div style={{ width: '60%', margin: ' 0 auto' }}>
            <InverlevyHeader fontSize={'4em'} color={'#ff9619'}>
              Torre Atoya
            </InverlevyHeader>
            <StyledP>Fluye en tu entorno</StyledP>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton type="button" backgroundColor={PRIMARYCOLOR} borderColor={PRIMARYCOLOR}
                onClick={() =>
                  navigate('/quote?product=9a36c2b1-243c-4e6e-881b-2757a4b06aec')
                }
              >
                Cotizar
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Atoya/Presentacion_Atoya.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Presentación
              </InverlevyButton>
              &nbsp;
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://www.youtube.com/watch?v=sfhwH920rEE&list=PLO4XW3c8uHP02B4CNH-YWCPSYBZZ77aJC&index=3', '_blank', 'noopener noreferrer')
                }
              >
                Video recorrido
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Atoya/Teaser_Atoya.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Teaser
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Lista precios
              </InverlevyButtonDisable>
              &nbsp;
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Atoya/Planos_Atoya.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Planos
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Obra
              </InverlevyButtonDisable>
            </div>
          </div>
        </Fade>
      </StyledInfoWrapper>}

      {state.loggedIn && (state.roles.includes(SUPERADMIN_ROLE) || state.roles.includes(AGENT_YAXCHE_ROLE) || state.roles.includes(AGENT_ROLE)) && <StyledInfoWrapper backgroundColor={'#F1F3F5'}>
        <Fade left cascade>
          <div style={{ width: '60%', margin: ' 0 auto' }}>
            <InverlevyHeader fontSize={'4em'} color={'#92D050'}>
              Yaxche Departamentos
            </InverlevyHeader>
            <StyledP>La mejor ubicación en Playa del Carmen</StyledP>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton type="button" backgroundColor={PRIMARYCOLOR} borderColor={PRIMARYCOLOR}
                onClick={() =>
                  navigate('/quote?product=994c787d-15f4-4a7d-ad11-c5fd9a0a6b3b')
                }
              >
                Cotizar
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Yaxche_depas/Presentacion_Yaxche.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Presentación
              </InverlevyButton>
              &nbsp;
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://www.youtube.com/watch?v=MX-ahwu2DjY&list=PLO4XW3c8uHP02B4CNH-YWCPSYBZZ77aJC&index=4', '_blank', 'noopener noreferrer')
                }
              >
                Video recorrido
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                 onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Yaxche_depas/Teaser_Yaxche.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Teaser
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('')
              // }
              >
                Lista precios
              </InverlevyButtonDisable>
              &nbsp;
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                 onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Yaxche_depas/Planos_Yaxche.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Planos
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Obra
              </InverlevyButtonDisable>
            </div>
          </div>
        </Fade>
        <Fade right>
          <div>
            <img
              style={{ borderRadius: '50px', width: '90%' }}
              src={'https://heicommunitytmp.blob.core.windows.net/realtor/projects/main/994c787d-15f4-4a7d-ad11-c5fd9a0a6b3b.jpg'}
              alt="Yaxche Departamentos"
            />
          </div>
        </Fade>
      </StyledInfoWrapper>}

      {state.loggedIn && (state.roles.includes(SUPERADMIN_ROLE) || state.roles.includes(AGENT_YAXCHE_ROLE) || state.roles.includes(AGENT_ROLE)) && <StyledInfoWrapper>
        <Fade left>
          <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img
              style={{ borderRadius: '50px', width: '90%' }}
              src={'https://heicommunitytmp.blob.core.windows.net/realtor/projects/main/a2842480-292d-415f-af51-556922bac68d.jpg'}
              alt="Yaxche Locales"
            />
          </div>
        </Fade>
        <Fade right cascade>
          <div style={{ width: '60%', margin: ' 0 auto' }}>
            <InverlevyHeader fontSize={'4em'} color={'#92D050'}>
              Yaxche Locales
            </InverlevyHeader>
            <StyledP>La mejor ubicación en Playa del Carmen</StyledP>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton type="button" backgroundColor={PRIMARYCOLOR} borderColor={PRIMARYCOLOR}
                onClick={() =>
                  navigate('/quote?product=a2842480-292d-415f-af51-556922bac68d')
                }
              >
                Cotizar
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                 onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Yaxche_locales/Presentacion_Yaxche.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Presentación
              </InverlevyButton>
              &nbsp;
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://www.youtube.com/watch?v=MX-ahwu2DjY&list=PLO4XW3c8uHP02B4CNH-YWCPSYBZZ77aJC&index=4', '_blank', 'noopener noreferrer')
                }
              >
                Video recorrido
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Yaxche_locales/Teaser_Yaxche.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Teaser
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Lista precios
              </InverlevyButtonDisable>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2' borderColor={PRIMARYCOLOR}
                // onClick={() =>
                //  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Yaxche_locales/Planos_Yaxche.pdf', '_blank', 'noopener noreferrer')
                //}
              >
                Planos
              </InverlevyButtonDisable>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Obra
              </InverlevyButtonDisable>
            </div>
          </div>
        </Fade>
      </StyledInfoWrapper>}

      {state.loggedIn && (state.roles.includes(SUPERADMIN_ROLE) || state.roles.includes(AGENT_ECA_ROLE) || state.roles.includes(AGENT_ROLE)) && <StyledInfoWrapper backgroundColor={'#F1F3F5'}>
        <Fade left cascade>
          <div style={{ width: '60%', margin: ' 0 auto' }}>
            <InverlevyHeader fontSize={'4em'} color={'#104358'}>
              Eca Do Queiros
            </InverlevyHeader>
            <StyledP>BEO Parque Lineal Eca Do Queiros</StyledP>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton type="button" backgroundColor={PRIMARYCOLOR} borderColor={PRIMARYCOLOR}
                onClick={() =>
                  navigate('/quote?product=7ce1e2f0-fff7-41c6-aa4a-e12ee4c0d1ca')
                }
              >
                Cotizar
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Eca Do/Presentacion_Eca.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Presentación
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2' borderColor={PRIMARYCOLOR}
              //onClick={() =>
              //  window.open('', '_blank', 'noopener noreferrer')
              //}
              >
                Video recorrido
              </InverlevyButtonDisable>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Eca Do/Teaser_Eca.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Teaser
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Lista precios
              </InverlevyButtonDisable>
              &nbsp;
              <InverlevyButton backgroundColor='#F2F2F2' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/Eca Do/Planos_Eca.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Planos
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Obra
              </InverlevyButtonDisable>
            </div>
          </div>
        </Fade>
        <Fade right>
          <div>
            <img
              style={{ borderRadius: '50px', width: '90%' }}
              src={'https://heicommunitytmp.blob.core.windows.net/realtor/projects/main/7ce1e2f0-fff7-41c6-aa4a-e12ee4c0d1ca.jpg'}
              alt="Eca Do Queiros"
            />
          </div>
        </Fade>
      </StyledInfoWrapper>}

      {state.loggedIn && (state.roles.includes(SUPERADMIN_ROLE) || state.roles.includes(AGENT_XXII_ROLE) || state.roles.includes(AGENT_ROLE)) && <StyledInfoWrapper>
        <Fade left>
          <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img
              style={{ borderRadius: '50px', width: '90%' }}
              src={'https://heicommunitytmp.blob.core.windows.net/realtor/projects/main/c5291cbb-1397-4c2d-b7e5-d0cd39c58e09.jpg'}
              alt="XXII"
            />
          </div>
        </Fade>
        <Fade right cascade>
          <div style={{ width: '60%', margin: ' 0 auto' }}>
            <InverlevyHeader fontSize={'4em'} color={'#104358'}>
              Edificio XXII
            </InverlevyHeader>
            <StyledP>Vive el centro</StyledP>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton type="button" backgroundColor={PRIMARYCOLOR} borderColor={PRIMARYCOLOR}
                onClick={() =>
                  navigate('/quote?product=c5291cbb-1397-4c2d-b7e5-d0cd39c58e09')
                }
              >
                Cotizar
              </InverlevyButton>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/XXII/Presentacion_XXII.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Presentación
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2' borderColor={PRIMARYCOLOR}
              //onClick={() =>
              //  window.open('', '_blank', 'noopener noreferrer')
              //}
              >
                Video recorrido
              </InverlevyButtonDisable>
            </div>
            &nbsp;
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/XXII/Teaser_XXII.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Teaser
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Lista precios
              </InverlevyButtonDisable>
              &nbsp;
              <InverlevyButton backgroundColor='#FFFFFF' color='#015cb2' borderColor={PRIMARYCOLOR}
                onClick={() =>
                  window.open('https://heicommunitytmp.blob.core.windows.net/realtor/resources/XXII/Planos_XXII.pdf', '_blank', 'noopener noreferrer')
                }
              >
                Planos
              </InverlevyButton>
              &nbsp;
              <InverlevyButtonDisable backgroundColor='#EAEAEA' color='#015cb2'
              // onClick={() =>
              //   navigate('/quote?product=EB04A05E-0408-4D9B-9B27-71A6C8EE1B4D')
              // }
              >
                Obra
              </InverlevyButtonDisable>
            </div>
          </div>
        </Fade>
      </StyledInfoWrapper>}

    </InverlevyStyledWrapper>
  )
}

export default Welcome
