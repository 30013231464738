import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Loader from "react-loader-spinner";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Modal from "@material-ui/core/Modal";

import { MONEYFORMATTER, PRIMARYCOLOR } from "../../../constants/utils";
import Wrapper from "../Wrapper/Wrapper.component";
import endpoints from "../../../api-service/endpoints";
import { InverlevyButton } from "../../InverlevyButton/InverlevyButton.component";
import {
  Checkbox,
  FormControlLabel,
  Slider,
  Typography,
  withStyles,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const isFullYear = (weeks) => {
  return weeks.reduce((accum, current) => (accum = accum + current.extra), 0);
};

const PrettoSlider = withStyles({
  root: {
    color: "green",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  mark: {
    backgroundColor: "transparent",
  },
})(Slider);

const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 0,
  };
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const parseErrorMessage = (error) => {
  var message = "";
  for (var key in error) {
    message = `${message} ${key} ${error[key].join(" ")}`;
  }
  return message;
};

const CondoHotelPreGain = ({ state, dispatch }) => {
  const [gain, setgain] = useState({});
  const [occupancy, setOccupancy] = useState(undefined);
  const [error, seterror] = useState({ message: "", display: false });
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState({});
  const [weeks, setWeeks] = useState([]);
  const [addOptional, setAddOptional] = useState(false);
  const [optionalExpenses, setOptionalExpenses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [marks, setmarks] = useState([]);
  const classes = useStyles();
  const onChangeSlider = async (value) => {
    setLoading(true);
    await loadResult(Math.floor(value, 2));
    setLoading(false);
    setOccupancy(value);
    dispatch({ type: "SET_QUOTE", payload: { occupancy: value } });
  };

  const debounceOnChange = useCallback(debounce(onChangeSlider, 400), [weeks]);
  const loadResult = async (occupancy) => {
    try {
      const body = {
        "project-id": state.quote.product,
        "unit-id": state.quote.unit.id,
        "trade-policy-id": state.quote.tradepolicy.id,
        date: state.quote["initial-date"],
        discount: state.quote.discount,
        price: state.quote["final-price"],
        deposit: state.quote["deposit-amount"],
        "condohotel-occupancy": occupancy ? occupancy : 0,
        "additions-payments": [
          ...state.quote["manual-additions"],
          ...state.quote["additions-payments"],
        ],
        "last-payment": state.quote["lastPayment-amount"],
        condohotel: state.isCondo,
        currency: state.currency,
        customer: {},
        "extra-weeks": weeks,
        "optional-expenses": optionalExpenses,
        "has-optional-expenses": addOptional,
      };
      const response = await axios.post(endpoints.CALCULATOR, body);
      setmarks([
        {
          value: 0,
          label: "0%",
        },
        {
          value: Math.floor(response.data.condohotel["occupancy-max"] * 100, 2),
          label: `${Math.floor(
            response.data.condohotel["occupancy-max"] * 100,
            2
          )}%`,
        },
      ]);
      setgain(response.data);

      setWeeks(response.data.condohotel["extra-weeks"]);
      dispatch({
        type: "SET_QUOTE", payload: {
          "extra-weeks": response.data.condohotel["extra-weeks"],
          occupancy: Math.floor(response.data.condohotel["occupancy"] * 100, 2)
        },
        optionalExpenses: [],
        addOptionalExpenses: false,
      });
      dispatch({ type: "SET_HAS_ERROR", payload: false });
      dispatch({ type: "SET_IS_CAPITALGAIN_LOADING", payload: false });
    } catch (error) {
      dispatch({ type: "SET_HAS_ERROR", payload: true });
      if (error && error.response && error.response.status === 400) {
        seterror({
          display: true,
          message: `${error.message} ${parseErrorMessage(error.response.data)}`,
        });
      } else {
        seterror({ display: true, message: error.message });
      }
      console.log(error);
    } finally {
      dispatch({ type: "SET_IS_CAPITALGAIN_LOADING", payload: false });
    }
  };

  useEffect(() => {
    loadResult(occupancy);
  }, [, optionalExpenses]);

  const openModal = (type) => {
    setOpen(true);

    if (type === "FEE") {
      const expenses = gain.condohotel["fee-details"];
      setModal({
        title: "Fee HEI Travel",
        body: expenses,
        showOptional: false,
      });
    } else {
      const expenses = gain.condohotel["expenses-details"];
      setModal({
        title: "Detalle de Operación (Egresos)",
        body: expenses,
        showOptional: true,
      });
    }
  };

  const body = () => (
    <div style={getModalStyle()} className={classes.paper}>
      <h2>{modal.title}</h2>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Detalle</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Porcentaje</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>USD</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>MXN</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {modal.body &&
              modal.body
                .filter((expense) => {
                  if (addOptional === false) {
                    return expense["is-optional"] === false;
                  } else {
                    return true;
                  }
                })
                .sort((a, b) => (a["is-optional"] > b["is-optional"] ? 1 : -1))
                .map((expense) => (
                  <TableRow
                    style={
                      expense["is-optional"]
                        ? { backgroundColor: "rgb(1,92,178,.08)" }
                        : null
                    }
                  >
                    <TableCell style={{ fontWeight: "bold" }}>
                      {expense.name}
                    </TableCell>
                    <TableCell>
                      {(expense.percentage * 100).toFixed(2)} %
                    </TableCell>
                    <TableCell>
                      {MONEYFORMATTER.format(
                        expense.cost / gain["exchange-rate"]
                      )}
                    </TableCell>
                    <TableCell>{MONEYFORMATTER.format(expense.cost)}</TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      {modal.showOptional && (
        <FormControlLabel
          style={{ display: "flex", marginLeft: 5 }}
          control={
            <Checkbox
              style={{ color: PRIMARYCOLOR, userSelect: "none" }}
              value={addOptional}
              onChange={(e) => {
                const value = e.target.checked;
                setAddOptional(value);
                dispatch({ type: "SET_QUOTE", payload: { addOptionalExpenses: value } });
              }}
              checked={addOptional}
              name="fondo"
            />
          }
          label="Fondo para renovación del equipamiento y mobiliario (opcional)"
        />
      )}
      <InverlevyButton
        onClick={() => {
          if (addOptional) {
            setOptionalExpenses([]);
            dispatch({ type: "SET_QUOTE", payload: { optionalExpenses: [] } });
          } else {
            const expenses = [
              ...modal.body.filter(
                (expense) => expense["is-optional"] === true
              ),
            ];
            setOptionalExpenses(expenses);
            dispatch({ type: "SET_QUOTE", payload: { optionalExpenses: expenses } });
          }

          setOpen(false);
        }}
        style={{ marginTop: 10 }}
      >
        Cerrar
      </InverlevyButton>
    </div>
  );

  if (error.message) {
    return (
      <Wrapper>
        <Snackbar
          open={error.display}
          autoHideDuration={6000}
          onClose={() => {
            seterror({ message: "", display: false });
          }}
        >
          <Alert
            onClose={() => {
              seterror({ message: "", display: false });
            }}
            severity="error"
          >
            {`Ocurrió un error (${error.message}) al generar la corrida, por favor intente de nuevo. Si el error persiste contacte soporte soporte@heicommunity.com`}
          </Alert>
        </Snackbar>
        :(
      </Wrapper>
    );
  }

  if (Object.keys(gain).length === 0 || loading === true)
    return (
      <Wrapper>
        <Loader type="ThreeDots" color={PRIMARYCOLOR} />{" "}
      </Wrapper>
    );

  return (
    <Wrapper>
      {state.isCapitalGainLoading === true ? (
        <Loader type="ThreeDots" color={PRIMARYCOLOR} />
      ) : (
        <div
          style={{
            display: "flex",
            flexFlow: "column",
            overflowY: "auto",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Modal
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {body()}
          </Modal>
          <div style={{ width: "90%" }}>
            <Typography
              variant="h5"
              style={{ color: "black", marginBottom: "10px" }}
            >
              {state.quote.unit.displayName}
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: 10,
                paddingTop: 10,
              }}
            >
              <div
                style={{
                  padding: "0 40px 0 10px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <PrettoSlider
                  onChange={(e, value) => debounceOnChange(value)}
                  min={0}
                  defaultValue={Math.floor(
                    gain.condohotel["occupancy"] * 100,
                    2
                  )}
                  max={Math.floor(gain.condohotel["occupancy-max"] * 100, 2)}
                  step={5}
                  valueLabelDisplay="on"
                  marks={marks}
                />
              </div>
              <div
                style={{
                  color: "rgba(1, 92, 178)",
                  backgroundColor: "rgba(1, 92, 178, 0.08)",
                  padding: "20px 20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "xx-large",
                    fontWeight: "bold",
                    margin: "0px 5px",
                  }}
                >
                  {occupancy
                    ? occupancy
                    : Math.floor(gain.condohotel["occupancy"] * 100, 2)}
                  %
                </span>
                <div
                  style={{
                    display: "inline-flex",
                    flexFlow: "column",
                    margin: "0px 5px",
                  }}
                >
                  <span>Porcentaje</span>
                  <span style={{ whiteSpace: "nowrap" }}>de ocupación</span>
                </div>
              </div>
            </div>
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Concepto
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>USD</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>MXN</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Detalle
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Inversión total</TableCell>
                    <TableCell>
                      {MONEYFORMATTER.format(
                        gain.condohotel["investment-total"] /
                        gain["exchange-rate"]
                      )}
                    </TableCell>
                    <TableCell>
                      {MONEYFORMATTER.format(
                        gain.condohotel["investment-total"]
                      )}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Tarifa promedio por noche</TableCell>
                    <TableCell>
                      {MONEYFORMATTER.format(
                        gain.condohotel["rate-per-night"] /
                        gain["exchange-rate"]
                      )}
                    </TableCell>
                    <TableCell>
                      {MONEYFORMATTER.format(gain.condohotel["rate-per-night"])}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Ingresos</TableCell>
                    <TableCell>
                      {MONEYFORMATTER.format(
                        gain.condohotel["annual-income"] / gain["exchange-rate"]
                      )}
                    </TableCell>
                    <TableCell>
                      {MONEYFORMATTER.format(gain.condohotel["annual-income"])}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Operación (egresos)</TableCell>
                    <TableCell>
                      {MONEYFORMATTER.format(
                        gain.condohotel["cost-operation"] /
                        gain["exchange-rate"]
                      )}
                    </TableCell>
                    <TableCell>
                      {MONEYFORMATTER.format(gain.condohotel["cost-operation"])}
                    </TableCell>
                    <TableCell>
                      <button
                        style={{
                          color: PRIMARYCOLOR,
                          border: 0,
                          backgroundColor: "transparent",
                          cursor: "pointer",
                        }}
                        onClick={() => openModal("OPERACION")}
                      >
                        Ver más
                      </button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Fee HEI Travel</TableCell>
                    <TableCell>
                      {MONEYFORMATTER.format(
                        gain.condohotel["management-fee"] /
                        gain["exchange-rate"]
                      )}
                    </TableCell>
                    <TableCell>
                      {MONEYFORMATTER.format(gain.condohotel["management-fee"])}
                    </TableCell>
                    <TableCell>
                      <button
                        style={{
                          color: PRIMARYCOLOR,
                          border: 0,
                          backgroundColor: "transparent",
                          cursor: "pointer",
                        }}
                        onClick={() => openModal("FEE")}
                      >
                        Ver más
                      </button>
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ backgroundColor: "rgb(1,92,178,.08)" }}>
                    <TableCell
                      style={{ fontWeight: "bold", color: PRIMARYCOLOR }}
                    >
                      Utilidad del propietario
                    </TableCell>
                    <TableCell style={{ color: PRIMARYCOLOR }}>
                      {MONEYFORMATTER.format(
                        gain.condohotel["net-annual-income"] /
                        gain["exchange-rate"]
                      )}
                    </TableCell>
                    <TableCell style={{ color: PRIMARYCOLOR }}>
                      {MONEYFORMATTER.format(
                        gain.condohotel["net-annual-income"]
                      )}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div style={{ width: "90%", marginTop: "2em", marginBottom: "1em" }}>
            <div
              style={{
                marginBottom: "1em",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <Typography variant="h5" style={{ color: "black" }}>
                  Semanas al año
                </Typography>
                <p style={{ color: "black", fontSize: ".6em", margin: "0px" }}>
                  *Sujeto a disponibilidad y ocupación
                </p>
              </div>
              <div
                style={{
                  color: "green",
                  backgroundColor: "honeydew",
                  padding: "20px 20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "xx-large",
                    fontWeight: "bold",
                    margin: "0px 5px",
                  }}
                >
                  {(gain.condohotel["roi-percentage"] * 100).toFixed(1)}%
                </span>
                <div
                  style={{
                    display: "inline-flex",
                    flexFlow: "column",
                    margin: "0px 5px",
                  }}
                >
                  <span>Return on</span>
                  <span>investment</span>
                </div>
                <span style={{ margin: "0px 5px" }}>
                  <svg
                    width="42"
                    height="33"
                    viewBox="0 0 42 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M41.009 4.97498C41.509 5.47498 41.759 6.08212 41.759 6.7964C41.759 7.51069 41.509 8.11783 41.009 8.61782L21.6162 28.0106L17.9733 31.6535C17.4733 32.1535 16.8662 32.4035 16.1519 32.4035C15.4376 32.4035 14.8304 32.1535 14.3304 31.6535L10.6876 28.0106L0.99115 18.3142C0.49115 17.8142 0.24115 17.2071 0.24115 16.4928C0.24115 15.7785 0.49115 15.1713 0.99115 14.6713L4.63401 11.0285C5.13401 10.5285 5.74116 10.2785 6.45544 10.2785C7.16972 10.2785 7.77687 10.5285 8.27686 11.0285L16.1519 18.9303L33.7232 1.33203C34.2232 0.832031 34.8304 0.582031 35.5447 0.582031C36.2589 0.582031 36.8661 0.832031 37.3661 1.33203L41.009 4.97498Z"
                      fill="#29823B"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Semanas
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="center">
                      Incluidas
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="center">
                      Adicionales
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Costo USD
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Costo MXN
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {weeks &&
                    weeks.map((week) => (
                      <TableRow key={week["rate-id"]}>
                        <TableCell>{week["rate-name"]}</TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {week.included}
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <button
                              disabled={week.extra === 0}
                              style={{
                                borderRadius: 12,
                                border: 0,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                const extraWeeks = [
                                  ...weeks.map((x) => {
                                    if (x["rate-id"] === week["rate-id"]) {
                                      x.extra -= 1;
                                    }
                                    return x;
                                  }),
                                ];
                                setWeeks(extraWeeks);
                                dispatch({ type: "SET_QUOTE", payload: { "extra-weeks": extraWeeks } });
                                loadResult(occupancy);
                              }}
                            >
                              -
                            </button>
                            {week.extra}
                            <button
                              disabled={isFullYear(weeks) === 6}
                              style={{
                                borderRadius: 7,
                                border: 0,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                const extraWeeks = [
                                  ...weeks.map((x) => {
                                    if (x["rate-id"] === week["rate-id"]) {
                                      x.extra += 1;
                                    }
                                    return x;
                                  }),
                                ];
                                setWeeks(extraWeeks);
                                dispatch({ type: "SET_QUOTE", payload: { "extra-weeks": extraWeeks } });
                                loadResult(occupancy);
                              }}
                            >
                              +
                            </button>
                          </div>
                        </TableCell>
                        <TableCell>
                          {MONEYFORMATTER.format(
                            week.cost / gain["exchange-rate"]
                          )}
                        </TableCell>
                        <TableCell>
                          {MONEYFORMATTER.format(week.cost)}
                        </TableCell>
                      </TableRow>
                    ))}
                  <TableRow style={{ backgroundColor: "rgb(1,92,178,.08)" }}>
                    <TableCell
                      style={{ fontWeight: "bold", color: PRIMARYCOLOR }}
                    >
                      Total
                    </TableCell>
                    <TableCell
                      style={{ color: PRIMARYCOLOR, textAlign: "center" }}
                    >
                      {gain.condohotel["extra-weeks"].reduce(
                        (a, b) => a + b.included,
                        0
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ color: PRIMARYCOLOR, textAlign: "center" }}
                    >
                      {gain.condohotel["extra-weeks"].reduce(
                        (a, b) => a + b.extra,
                        0
                      )}
                    </TableCell>
                    <TableCell style={{ color: PRIMARYCOLOR }}>
                      {MONEYFORMATTER.format(
                        gain.condohotel["extra-weeks"].reduce(
                          (a, b) => a + b.cost,
                          0
                        ) / gain["exchange-rate"]
                      )}
                    </TableCell>
                    <TableCell style={{ color: PRIMARYCOLOR }}>
                      {MONEYFORMATTER.format(
                        gain.condohotel["extra-weeks"].reduce(
                          (a, b) => a + b.cost,
                          0
                        )
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default CondoHotelPreGain;

CondoHotelPreGain.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
};
