import React, { useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Loader from "react-loader-spinner";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import { PRIMARYCOLOR } from "../../../constants/utils";
import Wrapper from "../Wrapper/Wrapper.component";
import QuoteDetailsBox from "../QuoteDetailsBox";
import endpoints from "../../../api-service/endpoints";
import styled from "styled-components";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CapitalGainWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  width: 100%;
`;

const parseErrorMessage = (error) => {
  var message = "";
  for (var key in error) {
    message = `${message} ${key} ${error[key].join(" ")}`;
  }
  return message;
};

const CondoHotelGain = ({ state, dispatch }) => {
  const [gain, setgain] = useState({});
  const [error, seterror] = useState({ message: "", display: false });

  const loadResult = async () => {
    try {
      const body = {
        "project-id": state.quote.product,
        "unit-id": state.quote.unit.id,
        "trade-policy-id": state.quote.tradepolicy.id,
        date: state.quote["initial-date"],
        discount: state.quote.discount,
        price: state.quote["final-price"],
        deposit: state.quote["deposit-amount"],
        "additions-payments": [
          ...state.quote["manual-additions"],
          ...state.quote["additions-payments"],
        ],
        "last-payment": state.quote["lastPayment-amount"],
        "condohotel": state.isCondo,
        currency: state.currency,
        customer: {},
      };
      const response = await axios.post(endpoints.CALCULATOR, body);
      setgain(response.data);
      dispatch({ type: "SET_HAS_ERROR", payload: false });
      dispatch({ type: "SET_IS_CAPITALGAIN_LOADING", payload: false });
    } catch (error) {
      dispatch({ type: "SET_HAS_ERROR", payload: true });
      if (error && error.response && error.response.status === 400) {
        seterror({
          display: true,
          message: `${error.message} ${parseErrorMessage(error.response.data)}`,
        });
      } else {
        seterror({ display: true, message: error.message });
      }
      console.log(error);
    } finally {
      dispatch({ type: "SET_IS_CAPITALGAIN_LOADING", payload: false });
    }
  };

  useEffect(() => {
    loadResult();
  }, []);

  if (error.message) {
    return (
      <Wrapper>
        <Snackbar
          open={error.display}
          autoHideDuration={6000}
          onClose={() => {
            seterror({ message: "", display: false });
          }}
        >
          <Alert
            onClose={() => {
              seterror({ message: "", display: false });
            }}
            severity="error"
          >
            {`Ocurrió un error (${error.message}) al generar la corrida, por favor intente de nuevo. Si el error persiste contacte soporte soporte@heicommunity.com`}
          </Alert>
        </Snackbar>
        :(
      </Wrapper>
    );
  }
  if (Object.keys(gain).length === 0) return <Wrapper> <Loader type="ThreeDots" color={PRIMARYCOLOR} /> </Wrapper>

  if (Object.keys(gain).length === 0) return <Wrapper> <Loader type="ThreeDots" color={PRIMARYCOLOR} /> </Wrapper>

  return (
    <Wrapper>
      {state.isCapitalGainLoading === true ? (
        <Loader type="ThreeDots" color={PRIMARYCOLOR} />
      ) : (
        <CapitalGainWrapper hasExtraOperation={true}>
          <QuoteDetailsBox
            value={gain["condohotel"]["net-annual-income"]}
            title={"Ingreso anual neto"}
            active
          />
          <QuoteDetailsBox
            value={`${(gain['condohotel']['roi-years']).toFixed(1)} años`}
            gainPercentage={gain['condohotel']['roi-percentage']}
            title={'Retorno inversión'}
            subtitle={"ROI"}
            active
            isText
          />
          <QuoteDetailsBox
            value={gain['investment'] + state.quote['unit']['equipmentPrice'] + state.quote['unit']['preOperatingCost']}
            gainPercentage={gain['roi']}
            title={"Inversión total"}
            subtitle={"Porcentaje de capitalización"}
            active
          />
          <QuoteDetailsBox
            value={gain["minimum-expected-value"]}
            title={"Valor minimo final"}
            main
          />
        </CapitalGainWrapper>
      )}
    </Wrapper>
  );
};

export default CondoHotelGain;

CondoHotelGain.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
};
