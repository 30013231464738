import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  max-height: 60vh;
  overflow:auto;
`
export default Wrapper
