import React, { useEffect, useState } from "react";
import axios from "axios";
import endpoints from "../../api-service/endpoints";


import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Paper from "@material-ui/core/Paper";
import Loader from "react-loader-spinner";
import { PRIMARYCOLOR } from "../../constants/utils";

const ExpensesReport = () => {
    const [expenseReport, setExpenseReport] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getProject = async () => {
        let { data } = await axios.get(endpoints.EXPENSEREPORT);
        setExpenseReport(data);
        setIsLoading(false);
    };

    useEffect(() => {
        getProject();
    }, []);

    //// TODO: opt. (1)
    // const getUnits = async (id) => {
    //     return await axios.get(`${endpoints.PROJECT}/${id}/units`);
    // };

    if (isLoading) {
        return (
            <Paper>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Loader type="ThreeDots" color={PRIMARYCOLOR} />
                </div>
            </Paper>
        );
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell cell="categories" key="categories">         </TableCell>
                            {expenseReport.projects.map((cell) => (
                                <TableCell cell={cell.id} key={cell.id}>{cell.name} ({cell.numberOfUnits})</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {expenseReport.expenses.map((row) => (
                            <TableRow>
                                <TableCell cell={row.id} key={row.id}>{row.name}</TableCell>
                                {row.details.map((detail) => (
                                    <TableCell cell={detail.projectId + detail.expenseId} key={detail.projectId + detail.expenseId} align="left">
                                        <div>
                                            ${detail.value}  <b>{detail.percentage}%</b>
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default ExpensesReport;