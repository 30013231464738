import React from "react";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import * as moment from "moment";

import TextField from "@material-ui/core/TextField";
import { PRIMARYCOLOR, 
MARGARITA_HOUSES, MARGARITA_APARTMENTS_AB, MARGARITA_APARTMENT_C, MARGARITA_APARTMENT_D, MARGARITA_APARTMENT_E } from "../../../constants/utils";
import Wrapper from "../Wrapper/Wrapper.component";
import QuoteDetailsBox from "../QuoteDetailsBox";
import { FormControlLabel, InputLabel, NativeSelect } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Loader from "react-loader-spinner";

const minDate = moment().format("yyyy-MM-DD");

const DISPONIBLE = 0;
const APARTADO = 1;
const VENDIDO = 2;
export const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
};

const compareUnits = (unitA, unitB) => {
  const [letterA, numberA] = unitA.displayName.split('-');
  const [letterB, numberB] = unitB.displayName.split('-');

  // Función para comparar letras personalizada (A < B < C ... < F)
  const letterOrder = {
    A: 1,
    B: 2,
    C: 3,
    D: 4,
    E: 5,
    F: 6,
  };

  // Compara las letras basado en el orden definido en letterOrder
  if (letterOrder[letterA] < letterOrder[letterB]) return -1;
  if (letterOrder[letterA] > letterOrder[letterB]) return 1;

  // Si las letras son iguales, compara los números como números enteros
  return parseInt(numberA, 10) - parseInt(numberB, 10);
};

const Unit = ({ state, dispatch }) => {
  const onDateTimeChange = (e) => {
    dispatch({
      type: "SET_QUOTE",
      payload: { "initial-date": e.target.value },
    });
  };

  const onIsCondoHotelChange = (e) => {
    const value = e.target.checked;
    dispatch({ type: "SET_IS_CONDO", payload: { isCondo: value } });
    dispatch({
      type: "SET_QUOTE",
      payload: { "condohotel": value },
    });
  };

  if (state.isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {" "}
        <Loader type="ThreeDots" color={PRIMARYCOLOR} />{" "}
      </div>
    );
  }

  const sortedUnits = state.quote.units.sort(compareUnits);

  return state.quote.product !== MARGARITA_HOUSES && state.quote.product !== MARGARITA_APARTMENTS_AB && state.quote.product !== MARGARITA_APARTMENT_C && state.quote.product !== MARGARITA_APARTMENT_D && state.quote.product !== MARGARITA_APARTMENT_E ? (
    <Wrapper style={{ flexFlow: "column" }}>
      <TextField
        color="secondary"
        required
        id="initialdate"
        name="initialdate"
        label="Fecha Inicial"
        type="date"
        onChange={onDateTimeChange}
        value={state.quote["initial-date"]}
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        style={{
          width: "25em",
          margin: "1em 0px",
          labelRoot: {
            fontSize: "30px !important",
          },
        }}
        InputProps={{ inputProps: { min: minDate } }}
      />
      <div style={{ display: "flex", alignItems: "row" }}>
        <div>
          <InputLabel shrink htmlFor="unidad">
            Unidad
          </InputLabel>
          <NativeSelect
            value={state.quote.unit ? state.quote.unit.id : "-1"}
            onChange={(e) => {
              const selectedUnit = state.quote.units.find(
                (x) => x.id === e.target.value
              );
              dispatch({ type: "SET_QUOTE", payload: { unit: selectedUnit } });

              if (selectedUnit.allowsCondoHotel && !selectedUnit.isCondoHotelOptional) {
                dispatch({ type: "SET_IS_CONDO", payload: { isCondo: true } });
              }
            }}
            name="unidad"
            label="Unidad"
            style={{ margin: "1em 0px", width: "25em" }}
            inputProps={{ "aria-label": "membership", id: "unit-selector" }}
          >
            <option value="-1" disabled>
              Ninguna
            </option>
            <optgroup label="Disponible">
              {state.quote &&
                state.quote.units &&
                state.quote.units
                  .filter((x) => x.isActive && x.status === DISPONIBLE)
                  .sort((a, b) => a.level - b.level || a.number - b.number)
                  .map((u) => (
                    <option key={u.id} value={u.id}>
                      {" "}
                      {u.displayName}
                    </option>
                  ))}
            </optgroup>
            <optgroup label="Apartado">
              {state.quote &&
                state.quote.units &&
                state.quote.units
                  .filter((x) => x.isActive && x.status === APARTADO)
                  .sort((a, b) => a.level - b.level || a.number - b.number)
                  .map((u) => (
                    <option disabled key={u.id} value={u.id}>
                      {" "}
                      {u.displayName}
                    </option>
                  ))}
            </optgroup>
            <optgroup label="Vendido">
              {state.quote &&
                state.quote.units &&
                state.quote.units
                  .filter((x) => x.isActive && x.status === VENDIDO)
                  .sort((a, b) => a.level - b.level || a.number - b.number)
                  .map((u) => (
                    <option disabled key={u.id} value={u.id}>
                      {" "}
                      {u.displayName}
                    </option>
                  ))}
            </optgroup>
          </NativeSelect>
        </div>
        {(state.quote && state.quote.unit && state.quote.unit.allowsCondoHotel) && <FormControlLabel
          style={{ display: "flex", marginLeft: 5 }}
          control={
            <Checkbox
              style={{ color: PRIMARYCOLOR, userSelect: 'none' }}
              value={state.isCondo}
              onChange={onIsCondoHotelChange}
              checked={state.isCondo}
              disabled={!state.quote.unit.isCondoHotelOptional}
              name="aportaciones"
            />
          }
          label="Condo Hotel"
        />}
      </div>
      {state.quote.unit && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "1em",
          }}
        >
          <QuoteDetailsBox
            value={state.quote.unit.displayName}
            height="70px"
            title={"Nombre"}
            fontSize={22}
            isText
          />
          <QuoteDetailsBox
            value={state.quote.unit.level > 0 ? state.quote.unit.level : "PB"}
            height="70px"
            title={"Nivel"}
            fontSize={22}
            isText
          />
          <QuoteDetailsBox
            value={state.quote.unit.grossArea}
            height="70px"
            title={"Superficie total (Metros cuadrados)"}
            fontSize={22}
            isText
          />
          <QuoteDetailsBox
            value={state.quote.unit.price}
            height="70px"
            title={"Precio de lista"}
            fontSize={22}
          />
          {state.isCondo && (
            <QuoteDetailsBox
              value={state.quote.unit.equipmentPrice}
              height="70px"
              title={"Precio inicial del Condo Hotel"}
              fontSize={22}
            />
          )}
          {state.isCondo && (
            <QuoteDetailsBox
              value={state.quote.unit.preOperatingCost > 0 ? state.quote.unit.preOperatingCost : "Incluido en el precio de equipamiento"}
              isText={state.quote.unit.preOperatingCost > 0 ? false : true}
              height="70px"
              title={"Precio preoperativo"}
              fontSize={state.quote.unit.preOperatingCost > 0 ? 22 : 16}
            />
          )}
        </div>
      )}
    </Wrapper>
  ):(
    state.quote.product === MARGARITA_APARTMENTS_AB || state.quote.product === MARGARITA_APARTMENT_C || state.quote.product === MARGARITA_APARTMENT_D || state.quote.product === MARGARITA_APARTMENT_E ? (
    <Wrapper style={{ flexFlow: "column" }}>
      <TextField
        color="secondary"
        required
        id="initialdate"
        name="initialdate"
        label="Initial date"
        type="date"
        onChange={onDateTimeChange}
        value={state.quote["initial-date"]}
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        style={{
          width: "25em",
          margin: "1em 0px",
          labelRoot: {
            fontSize: "30px !important",
          },
        }}
        InputProps={{ inputProps: { min: minDate } }}
      />
      <div style={{ display: "flex", alignItems: "row" }}>
        <div>
          <InputLabel shrink htmlFor="unit">
            Unit
          </InputLabel>
          <NativeSelect
            value={state.quote.unit ? state.quote.unit.id : "-1"}
            onChange={(e) => {
              const selectedUnit = state.quote.units.find(
                (x) => x.id === e.target.value
              );
              dispatch({ type: "SET_QUOTE", payload: { unit: selectedUnit } });

              if (selectedUnit.allowsCondoHotel && !selectedUnit.isCondoHotelOptional) {
                dispatch({ type: "SET_IS_CONDO", payload: { isCondo: true } });
              }
            }}
            name="unit"
            label="Unit"
            style={{ margin: "1em 0px", width: "25em" }}
            inputProps={{ "aria-label": "membership", id: "unit-selector" }}
          >
            <option value="-1" disabled>
              None
            </option>

            <optgroup label="Available">
              {sortedUnits
                .filter((x) => x.isActive && x.status === DISPONIBLE)
                .map((u) => (
                  <option key={u.id} value={u.id}>
                    {u.displayName}
                  </option>
                ))}
            </optgroup>
            <optgroup label="On hold">
              {sortedUnits
                .filter((x) => x.isActive && x.status === APARTADO)
                .map((u) => (
                  <option disabled key={u.id} value={u.id}>
                    {u.displayName}
                  </option>
                ))}
            </optgroup>
            <optgroup label="Sold">
              {sortedUnits
                .filter((x) => x.isActive && x.status === VENDIDO)
                .map((u) => (
                  <option disabled key={u.id} value={u.id}>
                    {u.displayName}
                  </option>
                ))}
            </optgroup>
          </NativeSelect>
        </div>
        {(state.quote && state.quote.unit && state.quote.unit.allowsCondoHotel) && <FormControlLabel
          style={{ display: "flex", marginLeft: 5 }}
          control={
            <Checkbox
              style={{ color: PRIMARYCOLOR, userSelect: 'none' }}
              value={state.isCondo}
              onChange={onIsCondoHotelChange}
              checked={state.isCondo}
              disabled={!state.quote.unit.isCondoHotelOptional}
              name="Contributions"
            />
          }
          label="Condo Hotel"
        />}
      </div>
      {state.quote.unit && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "1em",
          }}
        >
          <QuoteDetailsBox
            value={state.quote.unit.displayName}
            height="70px"
            title={"Name"}
            fontSize={22}
            isText
          />
          <QuoteDetailsBox
            value={state.quote.unit.level > 0 ? state.quote.unit.level : "Ground Floor"}
            height="70px"
            title={"Level"}
            fontSize={22}
            isText
          />
          <QuoteDetailsBox
            value={
              <span>
                {parseFloat(state.quote.unit.grossArea).toFixed(2)}
              </span>
            }
            height="70px"
            title={"Total area (Square feet)"}
            fontSize={22}
            isText
          />
          <QuoteDetailsBox
            value={state.quote.unit.price}
            height="70px"
            title={"List price (USD)"}
            fontSize={22}
          />
          {state.isCondo && (
            <QuoteDetailsBox
              value={state.quote.unit.equipmentPrice}
              height="70px"
              title={"Initial price of the Condo Hotel"}
              fontSize={22}
            />
          )}
          {state.isCondo && (
            <QuoteDetailsBox
              value={state.quote.unit.preOperatingCost > 0 ? state.quote.unit.preOperatingCost : "Included in the equipment price"}
              isText={state.quote.unit.preOperatingCost > 0 ? false : true}
              height="70px"
              title={"Pre-operating price"}
              fontSize={state.quote.unit.preOperatingCost > 0 ? 22 : 16}
            />
          )}
        </div>
      )}
    </Wrapper>
    ):(
    <Wrapper style={{ flexFlow: "column" }}>
      <TextField
        color="secondary"
        required
        id="initialdate"
        name="initialdate"
        label="Initial date"
        type="date"
        onChange={onDateTimeChange}
        value={state.quote["initial-date"]}
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        style={{
          width: "25em",
          margin: "1em 0px",
          labelRoot: {
            fontSize: "30px !important",
          },
        }}
        InputProps={{ inputProps: { min: minDate } }}
      />
      <div style={{ display: "flex", alignItems: "row" }}>
        <div>
          <InputLabel shrink htmlFor="unit">
            Unit
          </InputLabel>
          <NativeSelect
            value={state.quote.unit ? state.quote.unit.id : "-1"}
            onChange={(e) => {
              const selectedUnit = state.quote.units.find(
                (x) => x.id === e.target.value
              );
              dispatch({ type: "SET_QUOTE", payload: { unit: selectedUnit } });

              if (selectedUnit.allowsCondoHotel && !selectedUnit.isCondoHotelOptional) {
                dispatch({ type: "SET_IS_CONDO", payload: { isCondo: true } });
              }
            }}
            name="unit"
            label="Unit"
            style={{ margin: "1em 0px", width: "25em" }}
            inputProps={{ "aria-label": "membership", id: "unit-selector" }}
          >
            <option value="-1" disabled>
              None
            </option>
            <optgroup label="Available">
              {state.quote &&
                state.quote.units &&
                state.quote.units
                  .filter((x) => x.isActive && x.status === DISPONIBLE)
                  .sort((a, b) => a.level - b.level || a.number - b.number)
                  .map((u) => (
                    <option key={u.id} value={u.id}>
                      {" "}
                      {u.displayName}
                    </option>
                  ))}
            </optgroup>
            <optgroup label="On hold">
              {state.quote &&
                state.quote.units &&
                state.quote.units
                  .filter((x) => x.isActive && x.status === APARTADO)
                  .sort((a, b) => a.level - b.level || a.number - b.number)
                  .map((u) => (
                    <option disabled key={u.id} value={u.id}>
                      {" "}
                      {u.displayName}
                    </option>
                  ))}
            </optgroup>
            <optgroup label="Sold">
              {state.quote &&
                state.quote.units &&
                state.quote.units
                  .filter((x) => x.isActive && x.status === VENDIDO)
                  .sort((a, b) => a.level - b.level || a.number - b.number)
                  .map((u) => (
                    <option disabled key={u.id} value={u.id}>
                      {" "}
                      {u.displayName}
                    </option>
                  ))}
            </optgroup>
          </NativeSelect>
        </div>
        {(state.quote && state.quote.unit && state.quote.unit.allowsCondoHotel) && <FormControlLabel
          style={{ display: "flex", marginLeft: 5 }}
          control={
            <Checkbox
              style={{ color: PRIMARYCOLOR, userSelect: 'none' }}
              value={state.isCondo}
              onChange={onIsCondoHotelChange}
              checked={state.isCondo}
              disabled={!state.quote.unit.isCondoHotelOptional}
              name="Contributions"
            />
          }
          label="Condo Hotel"
        />}
      </div>
      {state.quote.unit && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "1em",
          }}
        >
          <QuoteDetailsBox
            value={state.quote.unit.displayName}
            height="70px"
            title={"Name"}
            fontSize={22}
            isText
          />
          <QuoteDetailsBox
            value={state.quote.unit.level > 0 ? state.quote.unit.level : "Ground Floor"}
            height="70px"
            title={"Level"}
            fontSize={22}
            isText
          />
          <QuoteDetailsBox
            value={
              <span>
                {parseFloat(state.quote.unit.grossArea).toFixed(2)}
              </span>
            }
            height="70px"
            title={"Total area (Square feet)"}
            fontSize={22}
            isText
          />
          <QuoteDetailsBox
            value={state.quote.unit.price}
            height="70px"
            title={"List price (USD)"}
            fontSize={22}
          />
          {state.isCondo && (
            <QuoteDetailsBox
              value={state.quote.unit.equipmentPrice}
              height="70px"
              title={"Initial price of the Condo Hotel"}
              fontSize={22}
            />
          )}
          {state.isCondo && (
            <QuoteDetailsBox
              value={state.quote.unit.preOperatingCost > 0 ? state.quote.unit.preOperatingCost : "Included in the equipment price"}
              isText={state.quote.unit.preOperatingCost > 0 ? false : true}
              height="70px"
              title={"Pre-operating price"}
              fontSize={state.quote.unit.preOperatingCost > 0 ? 22 : 16}
            />
          )}
        </div>
      )}
    </Wrapper>
    )
  );
};

export default Unit;

Unit.propTypes = {
  dispatch: PropTypes.func.isRequired,
  state: PropTypes.object,
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
