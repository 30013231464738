import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Loader from "react-loader-spinner";
import { PRIMARYCOLOR } from "../../constants/utils";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import ExpensesReport from '../../components/Reports/ExpensesReport.component';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  )
}

const Reports = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [state, setstate] = React.useState(0)

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const handleChange = (event, newValue) => {
    setstate(newValue)
  }

  if (isLoading) {
    return (
      <Paper>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader type="ThreeDots" color={PRIMARYCOLOR} />
        </div>
      </Paper>
    );
  }

  return <div>
    <Tabs style={{ backgroundColor: 'white' }} value={state} onChange={handleChange} >
      <Tab label="Gastos CondoHotel" />
      <Tab label="Report 2" />
      <Tab label="Report 3" />
    </Tabs>

    <TabPanel value={state} index={0}>
      <ExpensesReport />
    </TabPanel>
    <TabPanel value={state} index={1}>
      <div> Report 2 </div>
    </TabPanel>
    <TabPanel value={state} index={2}>
      <div> Report 3 </div>
    </TabPanel>
  </div >
}

export default Reports;
