export default {
  // Production
  LOGIN: 'https://heirealtor-quotes-api-win.azurewebsites.net/api/accounts/auth',
  // PARAMS: 'https://heirealtor-quotes-api-win.azurewebsites.net/api/system/params',
  CALCULATOR: 'https://heirealtor-quotes-api-win.azurewebsites.net/api/quotes',
  USER: 'https://heirealtor-quotes-api-win.azurewebsites.net/api/users',
  ROLE: 'https://heirealtor-quotes-api-win.azurewebsites.net/api/roles',
  ACCOUNT: 'https://heirealtor-quotes-api-win.azurewebsites.net/api/accounts',
  PROJECT: 'https://heirealtor-quotes-api-win.azurewebsites.net/api/projects',
  UNIT: 'https://heirealtor-quotes-api-win.azurewebsites.net/api/units',
  TRADEPOLICY: 'https://heirealtor-quotes-api-win.azurewebsites.net/api/tradepolicies',
  EXPENSEREPORT: 'https://heirealtor-quotes-api-win.azurewebsites.net/api/condohotelexpenses/report/',
  EXPENSES: 'https://heirealtor-quotes-api-win.azurewebsites.net/api/expenses'

  // // Local
  // LOGIN: 'https://localhost:53205/api/accounts/auth',
  // // PARAMS: 'https://localhost:53205/api/system/params',
  // CALCULATOR: 'https://localhost:53205/api/quotes',
  // USER: 'https://localhost:53205/api/users',
  // ROLE: 'https://localhost:53205/api/roles',
  // ACCOUNT: 'https://localhost:53205/api/accounts',
  // PROJECT: 'https://localhost:53205/api/projects',
  // UNIT: 'https://localhost:53205/api/units',
  // TRADEPOLICY: 'https://localhost:53205/api/tradepolicies',
  // EXPENSEREPORT: 'https://localhost:53205/api/condohotelexpenses/report/',
  // EXPENSES: 'https://localhost:53205/api/expenses'
}
